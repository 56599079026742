import "./Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { Link } from "react-router-dom";
import {IoLogoFacebook} from "react-icons/io5";
function Footer() {
  return (
    <footer className="footer_container">
      <div className="footer_nav">
        <div className="footer_nav_item">
          <Link className="footer_nav_link" to="/">
            Home
          </Link>
        </div>
        <div className="footer_nav_item">
          <Link className="footer_nav_link" to="/services">
            Services
          </Link>
        </div>
        <div className="footer_nav_item">
          <Link className="footer_nav_link" to="/contact-us">
            Contact Us
          </Link>
        </div>
      </div>
      <div className="socials_media">
        <a href="tel:+9710545555345">
          <IoMdCall className="ft_icon" />
        </a>
        {/* <a
          href="https://www.facebook.com/pluggt.dubai/"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF className="ft_icon" />
        </a> */}
        <a
          href="https://www.instagram.com/pluggt.dxb"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineInstagram className="ft_icon" />
        </a>
        <a
          href="https://www.facebook.com/pluggt.dxb"
          target="_blank"
          rel="noreferrer"
        >
          <IoLogoFacebook className="ft_icon" />
        </a>
         {/* <a href="face" target="_blank" rel="noreferrer">
          <BsTwitter className="ft_icon" />
        </a> */}
      </div>
      <div className="footer_copyright">
        <p>Copyright © 2022 PLUG GT. All rights reserved.</p>
    Powered By   <a href="http://impulsedmm.com/" className="powered">IDMM</a>

      </div>
    </footer>
  );
}

export default Footer;
