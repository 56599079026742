import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ToggleMenu from "../toggle";
import HumburgerMenu from "../../assets/menu.png";
import LogoImg from "../../assets/logo.png";
// import Button from "../Button";
// import ToggleMenu from "../ToggleMenu/ToggleMenu";

const Navbar = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollNav, setScrollNav] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const listenScrollEvent = () => {
    if (window.scrollY >= 20) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const navData = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "About",
      link: "/about-us",
    },
    {
      name: "Contact us",
      link: "/contact-us",
    },
  ];

  return (
    <HeaderContainer scrollNav={scrollNav} screenWidth={screenWidth}>
      <NavWrapper>
        <Link to="/">
          <Logo src={LogoImg} className="logo" />
        </Link>
        <NavList className="navList">
          {navData.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                to={item.link}
                className={`nav_link ${({ isActive }) =>
                  isActive ? "active" : null}

                  `}
              >
                {item.name}
              </NavLink>
            </NavItem>
          ))}
        </NavList>
        {screenWidth <= 768 && (
          <HamburgerButtonStyling
            onClick={() => setMenuOpen(!menuOpen)}
            menuOpen={menuOpen}
          >
            <img src={HumburgerMenu} alt="" />
          </HamburgerButtonStyling>
        )}
      </NavWrapper>
      <ToggleMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </HeaderContainer>
  );
};

const HamburgerButtonStyling = styled.div`
  width: 40px;
  height: 40px;
  z-index: 1;

  & img {
    width: 100%;
    height: 100%;

    transition: all 0.3s ease-in-out;

    transform: ${({ menuOpen }) =>
      menuOpen ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const HeaderContainer = styled.header`
  position: fixed;
  background: ${({ scrollNav }) =>
    scrollNav
      ? `linear-gradient(
    90deg,
    rgba(39, 56, 118, 1) 0%,
    rgb(33, 60, 156) 100%
  )`
      : "transparent !important"};
  box-shadow: ${({ scrollNav }) =>
    scrollNav ? "0 4px 2px -2px #ececec" : "none"};
  top: 0;
  right: 0;
  left: 0;
  //   transition: box-shadow 0.3s ease-in-out;
  z-index: 100;
  height: 80px;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--max-width);
  height: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  z-index: 10;
  background: transparent;

  @media only screen and (max-width: 768px) {
    width: 100%;
    & .navList,
    & .nav_btn {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const Logo = styled.img`
  heigth: auto;
  width: 150px;
  position: relative;
  z-index: 1;
`;

const NavList = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
`;
const NavItem = styled.li`
  color: white;
  font-family: "goldman";
  font-size: 1.2em;
  transition: 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

export default Navbar;
