import "./App.css";
import Footer from "./components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import LoadingComponent from "./components/loading";
import FloatButton from "./components/FloatButton";
import Navbar from "./components/navbar/NavBar";
import ScrollTop from "./utils/scrollTop";

const Home = React.lazy(() => import("./components/home/Home"));
const Services = React.lazy(() => import("./components/service/Services"));
const Contact = React.lazy(() => import("./components/contact/Contact"));
const Aboutbg = React.lazy(() => import("./components/about/Aboutbg"));

function App() {
  return (
    <div className="app">
      {/* <Header /> */}
      <Suspense fallback={<LoadingComponent />}>
        <ScrollTop />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/about-us" exact element={<Aboutbg />} />
          <Route path="/contact-us" exact element={<Contact />} />
        </Routes>
        <Footer />
        <FloatButton />
      </Suspense>
    </div>
  );
}

export default App;
