import React from "react";
import { PuffLoader } from "react-spinners";
import styled from "styled-components";

const LoadingComponent = () => {
  return (
    <Container>
      <PuffLoader
        color={"#fff"}
        loading={true}
        // cssOverride={override}
        size={60}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999999999999999999999;
  background: #1e2132;
`;

export default LoadingComponent;
