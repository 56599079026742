import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

// import "./toggleStyling.css";

const navData = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "About",
    link: "/about-us",
  },
  {
    name: "Contact us",
    link: "/contact-us",
  },
];

const ToggleMenu = ({ menuOpen, setMenuOpen }) => {
  const handleClose = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <MenuContainer menuOpen={menuOpen}>
      <NavList>
        {navData.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              to={item.link}
              onClick={handleClose}
              className={`nav_link ${({ isActive }) =>
                isActive ? "active" : null}
		  `}
            >
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </NavList>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 600px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(39, 56, 118, 1) 0%,
    rgb(33, 60, 156) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  transition: all 0.5s ease-in-out;
  z-index: 0;

  transform: ${({ menuOpen }) =>
    menuOpen ? "translateY(0)" : "translateY(-1000px)"};
`;

const NavList = styled.ul`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  list-style: none;
`;
const NavItem = styled.li`
  & .active {
    color: #fff !important;
    font-weight: 700;
    text-decoration: underline;
    border-bottom: 2px solid #fff;

    &:after {
      bottom: -4px;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      background: var(--primary-color) !important;
      transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
      width: 100% !important;
      opacity: 0.9 !important;
    }
  }
  & .nav_link {
    text-decoration: none;
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    color: #fff;

    &:after {
      bottom: -4px;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      background: #000;
      opacity: 0.6;
      transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
      width: 0;
    }

    &:hover:after {
      width: 100%;
      opacity: 0.9;
    }
  }

  & .nav_button {
    font-size: 1.5rem;
    padding: 10px 20px;
  }
`;

export default ToggleMenu;
